import RootPage from "../../components/RootPage/RootPage";
import TempBackButton from "../../components/TempBackButton/TempBackButton";
import involvedPageBackground from "./assets/images/involvedMenuBackgroundImage.webp";

import PopUpShop from "./assets/images/Pop-upShop.webp";
import GuideToF1 from "./assets/images/GuideToF1.webp";
import WilliamsHQ from "./assets/images/WilliamsHQ.webp";
import DownloadTheApp from "./assets/images/DownloadTheApp.webp";

const Involved = () => {
  const involvedCardImages = [
    // { card: PopUpShop, linkTo: "/popupshop" },
    { card: GuideToF1, linkTo: "/guidetof1" },
    { card: WilliamsHQ, linkTo: "/williamsexperience" },
    { card: DownloadTheApp, linkTo: "/download" },
  ];

  return (
    <>
      <RootPage
        backgroundImage={involvedPageBackground}
        cardImages={involvedCardImages}
        cardImageSize={{ height: 953, width: 1864 }}
        whiteText={"GET"}
        blueText={"INVOLVED"}
      />
      <TempBackButton />
    </>
  );
};

export default Involved;
