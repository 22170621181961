import { useLocation } from "react-router-dom";
import TopNav from "../../components/TopNav/TopNav";
import styles from "./assets/css/Driver.module.css";
import InPlaceVideo from "../../components/InPlaceVideo/InPlaceVideo";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

import leftArrow from "./assets/images/left_arrow.svg";
import rightArrow from "./assets/images/right_arrow.svg";
import { useEffect, useState } from "react";

const Driver = () => {
  const location = useLocation();
  const {
    bio,
    firstName,
    lastName,
    profileImage,
    type,
    images,
    video,
    videoPlaceholder,
  } = location.state.driver;

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setImageIndex(0);
  }, []);

  const handlePreviousImage = () => {
    setImageIndex(imageIndex - 1);
  };

  const handleNextImage = () => {
    setImageIndex(imageIndex + 1);
  };

  return (
    <section className={styles.sectionContainer}>
      <TopNav />
      <div className={styles.pageHeader}>
        {firstName && lastName && (
          <>
            <img
              src={profileImage}
              alt={`${firstName} ${lastName} profile`}
              className={styles.profileImage}
            />
            <div className={styles.driverName}>
              <h1>{firstName}</h1>
              <h1 className={styles.blueText}>{lastName}</h1>
            </div>
          </>
        )}
      </div>
      <div className={styles.driverBio}>
        {type && type === "williams driver" ? <h2>Driver</h2> : <h2>{type}</h2>}
        <p>{bio}</p>
      </div>
      <div className={styles.mediaContainer}>
        {video && video ? (
          // VIDEO
          <InPlaceVideo
            videoPlaceholder={videoPlaceholder}
            videoSource={video}
          />
        ) : images && images.length > 0 ? (
          <>
            {imageIndex > 0 ? (
              <img
                src={leftArrow}
                alt=""
                className={styles.leftArrow}
                onClick={handlePreviousImage}
              />
            ) : (
              ""
            )}
            {/* IMAGE GALLERY */}
            <div className={styles.imageGallery}>
              <img src={images[imageIndex]} alt="" className={styles.image} />
            </div>
            {imageIndex < images.length - 1 ? (
              <img
                src={rightArrow}
                alt=""
                className={styles.rightArrow}
                onClick={handleNextImage}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          // NO MEDIA
          ""
        )}
      </div>
      <ScrollDown />
      <TempBackButton />
    </section>
  );
};

export default Driver;
