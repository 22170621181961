import RootPage from "../../components/RootPage/RootPage";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

import OurPartners from "./assets/images/OurPartners.webp";
import PitCrew from "./assets/images/PitCrew.webp";
import WilliamsTeam from "./assets/images/WilliamsTeam.webp";

import teamPageBackground from "./assets/images/teamMenuBackgroundImage.webp";

const Team = () => {
  const teamCardImages = [
    { card: OurPartners, linkTo: "/partners" },
    { card: PitCrew, linkTo: "/pitcrew" },
    { card: WilliamsTeam, linkTo: "/leadership" },
  ];

  return (
    <>
      <RootPage
        backgroundImage={teamPageBackground}
        cardImages={teamCardImages}
        cardImageSize={{ height: 968, width: 1864 }}
        whiteText={"WILLIAMS"}
        blueText={"TEAM"}
      />
      <TempBackButton />
    </>
  );
};

export default Team;
