import styles from "./assets/css/PopupShop.module.css";
import TopNav from "../../components/TopNav/TopNav";
import headerImage from "./assets/images/popupShopHeader.webp";
import videoPlaceholder from "./assets/images/videoPlaceholder.webp";
import elementsSectionBackground from "./assets/images/elementsSectionBackground.webp";
import InPlaceVideo from "../../components/InPlaceVideo/InPlaceVideo";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import popupShopVideo from "./assets/videos/popup_shop.mp4";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Elements Images
import showCars from "./assets/images/elementImages/showCars.webp";
import appearances from "./assets/images/elementImages/appearances.webp";
import simRigs from "./assets/images/elementImages/eSportsRigs.webp";
import batak from "./assets/images/elementImages/batak.webp";
import merchandise from "./assets/images/elementImages/merchandise.webp";
import presentingPartner from "./assets/images/elementImages/presentingPartner.webp";
import heritageZone from "./assets/images/elementImages/heritageZone.webp";
import michelobBar from "./assets/images/elementImages/michelobUltraBar.webp";
import thg from "./assets/images/elementImages/thg.webp";
import benQ from "./assets/images/elementImages/benQ.webp";
import esteeLauder from "./assets/images/elementImages/esteeLauder.webp";
import treasureHunt from "./assets/images/elementImages/digitalTreasureHunt.webp";

const PopupShop = () => {
  const elementsCards = [
    {
      image: showCars,
      heading: "Show Cars",
      text: "A special US livery on the FW41 & our FW45 show car",
    },
    {
      image: appearances,
      heading: "Appearances",
      text: "Alex Albon, Logan Sargeant, James Vowles and Jenson Button",
    },
    {
      image: simRigs,
      heading: "Sim Rigs",
      text: "5 professional Esports simulators",
    },
    {
      image: batak,
      heading: "Batak",
      text: "2 x batak lites to test your speed",
    },
    {
      image: merchandise,
      heading: "Vegas Merchandise",
      text: "Items to include official Team Kit, Core range, Logan range and Las Vegas Collection. Discounts available for fans that show their driver card or 15% for members of the Williams Racing Collectibles + program",
    },
    {
      image: presentingPartner,
      heading: "Presenting Partner",
      text: "Kraken will be back promoting Williams Racing Collectibles + offering benefits to people that sign up which include; 15% of merchandise, access to the heritage zone and t-shirt personalisation",
    },
    {
      image: heritageZone,
      heading: "Heritage Zone",
      text: "An area of one of a kind Williams trophies & memorabilia",
    },
    {
      image: michelobBar,
      heading: "Michelob Ultra Bar",
      text: "Beer & seltzer sampling",
    },
    {
      image: thg,
      heading: "THG",
      text: "Sampling",
    },
    {
      image: benQ,
      heading: "BenQ",
      text: "Esports Partner",
    },
    {
      image: esteeLauder,
      heading: "Estee Lauder",
      text: "Kilian Paris fragrance bar",
    },
    {
      image: treasureHunt,
      heading: "Digital Treasure Hunt",
      text: "Find and scan all the QR codes in the Fan Zone to win Instant Prizes",
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <img className={styles.headerImage} src={headerImage} alt="" />
        <div className={styles.titleContainer}>
          <h1>Pop-up</h1>
          <h1 className={styles.blueText}>Shop</h1>
        </div>
      </div>
      <div className={styles.locationOverview}>
        <h2>Las Vegas Overview</h2>
        <div className={styles.locationInfo}>
          <div className={`${styles.locationBlock} ${styles.block1}`}>
            <h3 className={styles.blueText}>Location</h3>
            <p>Brooklyn Bridge,</p>
            <p>New York New York Hotel,</p>
            <p>Las Vegas</p>
          </div>
          <div className={`${styles.locationBlock} ${styles.block2}`}>
            <h3 className={styles.blueText}>Dates</h3>
            <p>13th - 18th November</p>
          </div>
          <div className={`${styles.locationBlock} ${styles.block3}`}>
            <h3 className={styles.blueText}>Hours</h3>
            <p>
              Monday: <span className={styles.blueText}>12:00 - 22:00</span>
            </p>
            <p>
              Tuesday: <span className={styles.blueText}>12:00 - 22:00</span>
            </p>
            <p>
              Wednesday: <span className={styles.blueText}>12:00 - 22:00</span>
            </p>
            <p>
              Thursday: <span className={styles.blueText}>12:00 - 22:00</span>
            </p>
            <p>
              Friday: <span className={styles.blueText}>12:00 - 22:00</span>
            </p>
            <p>
              Saturday: <span className={styles.blueText}>12:00 - 22:00</span>
            </p>
          </div>
        </div>
        <InPlaceVideo
          videoSource={popupShopVideo}
          videoPlaceholder={videoPlaceholder}
        />
      </div>
      <div className={styles.elementsSection}>
        <img
          src={elementsSectionBackground}
          alt=""
          className={styles.elementSectionImage}
        />
        <div className={styles.elementSectionContent}>
          <h3 className={styles.blueText}>Elements:</h3>
          <p>Here is a flavour of what else to expect:</p>
          <div className={styles.elementsCardContainer}>
            {elementsCards &&
              elementsCards.map((card, index) => (
                <div className={styles.card} key={index}>
                  {card?.image && (
                    <div className={styles.cardImageContainer}>
                      <img src={card.image} alt="" />
                    </div>
                  )}
                  <div className={styles.cardTextContainer}>
                    {card?.heading && <h4>{card.heading}</h4>}
                    {card?.text && <p>{card.text}</p>}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ScrollDown />
      <TempBackButton />
    </div>
  );
};
export default PopupShop;
