import backgroundImage from "./assets/images/Safety_Background.webp";
import styles from "./assets/css/Safety.module.css";
import TopNav from "../../components/TopNav/TopNav";
import { useState } from "react";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Image Content
import haloImage from "./assets/images/halo.webp";
import hansDeviceImage from "./assets/images/hansDevice.webp";
import kevlarImage from "./assets/images/kevlar.webp";
import nomexImage from "./assets/images/nomex.webp";
import safetyCarImage from "./assets/images/safetyCar.webp";
import survivalCellImage from "./assets/images/survivalCell.webp";
import { CSSTransition } from "react-transition-group";

const Safety = () => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);

  const safetyElements = [
    {
      name: "Halo",
      content:
        "Brought in for the 2018 season, the halo is a safety structure that surrounds the cockpit with the sole purpose of protecting the driver. Initially a controversial addition, any negativity has since subsided after several incidents where the halo has been credited with saving the life of the driver.",
      image: haloImage,
    },
    {
      name: "Hans Device",
      content:
        "Short for Head and Neck Support Device, it is a piece of equipment that rests on a driver's shoulders and is clipped onto their helmet, to help prevent sudden movements in the head and neck during an impact. They were made compulsory in Formula 1 before the 2003 season.",
      image: hansDeviceImage,
    },
    {
      name: "Kevlar",
      content:
        "A lightweight but strong synthetic fibre that is used in the construction of Formula 1 cars. A strong, but flexible and light material, ideal for making F1 fuel tanks. It is the very same material used in bulletproof vests.",
      image: kevlarImage,
    },
    {
      name: "Nomex",
      content:
        "A lightweight, fireproof material that is used to make a driver's overalls, gloves, boots and underwear.",
      image: nomexImage,
    },
    {
      name: "Safety Car",
      content:
        "A vehicle that is brought out to bunch up and control the pace of all the cars when an incident on track requires the race to be neutralised and cars to be slowed. Used permanently since 1993.",
      image: safetyCarImage,
    },
    {
      name: "Survival Cell",
      content:
        "The section of the car where the driver is located, built out of exceedingly strong carbon fibre composite alongside a layer of Kevlar. Every season, it undergoes extensive crash testing before it is approved for racing.",
      image: survivalCellImage,
    },
  ];

  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <div className={styles.backgroundContainer}>
        <img src={backgroundImage} className={styles.backgroundImage} alt="" />
      </div>
      <div className={styles.headingContainer}>
        <h1>SAFETY</h1>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menu}>
          <ul>
            {safetyElements &&
              safetyElements.map((element, index) => (
                <li
                  key={index}
                  className={activeMenuIndex === index ? styles.activeMenu : ""}
                  onClick={() => setActiveMenuIndex(index)}
                >
                  {element.name}
                </li>
              ))}
          </ul>
        </div>
        {safetyElements &&
          safetyElements.map((element, index) => (
            <CSSTransition
              in={activeMenuIndex === index}
              mountOnEnter
              unmountOnExit
              key={index}
              timeout={500}
              classNames="fade"
            >
              <div className={styles.safetyImage}>
                <img src={element.image} alt="" height={1966} width={1332} />
                <div className={styles.textContainer}>
                  <h2>{element.name}</h2>
                  <p>{element.content}</p>
                </div>
              </div>
            </CSSTransition>
          ))}
      </div>
      <TempBackButton />
    </section>
  );
};

export default Safety;
