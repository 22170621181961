import FlipCard from "../FlipCard/FlipCard";
import styles from "./assets/css/FlipCards.module.css";

const FlipCards = ({ cards }) => {
  return (
    <div className={styles.flipCardContainer}>
      {cards &&
        cards.map(
          ({ image, icon, backText, frontHeading, frontText }, index) => (
            <FlipCard
              image={image}
              icon={icon}
              backText={backText}
              frontText={frontText}
              frontHeading={frontHeading}
              key={index}
            />
          )
        )}
    </div>
  );
};

export default FlipCards;
