import TopNav from "../../components/TopNav/TopNav";
import styles from "./assets/css/Crew.module.css";
import backgroundImage from "./assets/images/crewBackgroundImage.webp";
import teamImage from "./assets/images/Team.webp";
import crewArlan from "./assets/images/Arlan.webp";
import crewRob from "./assets/images/Rob.webp";
import crewBilly from "./assets/images/Billy.webp";
import playIcon from "./assets/images/playIcon.webp";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

import pit_crew_video_arlan from "./assets/videos/pit_crew_arlan.mp4";
import pit_crew_video_rob from "./assets/videos/pit_crew_rob.mp4";
import pit_crew_video_billy from "./assets/videos/pit_crew_billy.mp4";
import VerticalVideoBox from "../../components/VerticalVideoBox/VerticalVideoBox";
import { useState } from "react";

const Crew = () => {
  const [videoSource, setVideoSource] = useState(null);

  const handleVideoEnded = () => {
    setVideoSource(null);
  };

  return (
    <section className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <div className={styles.backgroundContainer}>
          <img
            src={backgroundImage}
            className={styles.backgroundImage}
            alt=""
          />
        </div>
        <div className={styles.headingContainer}>
          <h1>Pit</h1>
          <h1 className={styles.blueText}>Crew</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <img src={teamImage} alt="" />

        <div className={styles.videos}>
          <div
            className={styles.videoCard}
            onClick={() => setVideoSource(pit_crew_video_arlan)}
          >
            <div className={styles.image}>
              <img src={crewArlan} alt="" />
            </div>
            <div className={styles.icon}>
              <img src={playIcon} alt="" />
            </div>
            <div className={styles.text}>
              <p>No.2</p>
              <p>Mechanic</p>
            </div>
          </div>

          <div
            className={styles.videoCard}
            onClick={() => {
              setVideoSource(pit_crew_video_rob);
            }}
          >
            <div className={styles.image}>
              <img src={crewRob} alt="" />
            </div>
            <div className={styles.icon}>
              <img src={playIcon} alt="" />
            </div>
            <div className={styles.text}>
              <p>Garage</p>
              <p>Technician</p>
            </div>
          </div>

          <div
            className={styles.videoCard}
            onClick={() => {
              setVideoSource(pit_crew_video_billy);
            }}
          >
            <div className={styles.image}>
              <img src={crewBilly} alt="" />
            </div>
            <div className={styles.icon}>
              <img src={playIcon} alt="" />
            </div>
            <div className={styles.text}>
              <p>Communications</p>
              <p>Technician</p>
            </div>
          </div>
        </div>
      </div>
      <VerticalVideoBox
        videoSource={videoSource}
        handleVideoEnded={handleVideoEnded}
      />
      <TempBackButton />
    </section>
  );
};

export default Crew;
