import backIcon from "./assets/images/backArrow.svg";
import styles from "./assets/css/TempBackButton.module.css";
import { useNavigate } from "react-router-dom";

const TempBackButton = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <button onClick={goBack} className={styles.backButton}>
        <img src={backIcon} alt="" />
      </button>
    </>
  );
};
export default TempBackButton;
