import { Link } from "react-router-dom";
import styles from "./assets/css/NavCard.module.css";

const NavCard = ({ whiteText, blueText, image, linkTo, pulse }) => {
  return (
    <Link to={linkTo}>
      <div className={styles.navCard}>
        <img src={image} alt="" className={pulse ? styles.pulseActive : ""} />
        <div className={styles.cardText}>
          <p>{whiteText}</p>
          <p>{blueText}</p>
        </div>
      </div>
    </Link>
  );
};

export default NavCard;
