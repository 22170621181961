import styles from "./assets/css/InPlaceVideo.module.css";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import playIconOverlay from "./assets/images/playIcon.webp";
import rewindIcon from "./assets/images/rewind.svg";
import pauseIcon from "./assets/images/pause.svg";
import muteIcon from "./assets/images/mute.svg";
import unmuteIcon from "./assets/images/unmute.svg";
import closeIcon from "./assets/images/X.svg";
import playIcon from "./assets/images/play.svg";

const InPlaceVideo = ({ videoSource, videoPlaceholder }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [pauseVideo, setPauseVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [videoOverlay, setVideoOverlay] = useState(false);
  const videoRef = useRef();

  const toggleMute = () => {
    setMuteVideo((currentState) => !currentState);
  };

  const togglePause = () => {
    setPauseVideo((currentState) => !currentState);
  };

  const closeVideo = () => {
    setPlayVideo(false);
    setPauseVideo(false);
    setMuteVideo(false);
  };

  const toggleVideoControls = () => {
    setVideoOverlay((currentState) => !currentState);
    setTimeout(() => {
      setVideoOverlay((currentState) => !currentState);
    }, 3000);
  };

  useEffect(() => {
    if (!videoRef.current) return;
    pauseVideo ? videoRef.current.pause() : videoRef.current.play();
  }, [pauseVideo]);

  const restartVideo = () => {
    videoRef.current.currentTime = 0;
  };

  return (
    <div className={styles.videoContainer}>
      {playVideo ? (
        <>
          <video
            ref={videoRef}
            src={videoSource}
            muted={muteVideo}
            autoPlay
            width={1828}
            onEnded={() => {
              setPlayVideo(false);
            }}
            onClick={toggleVideoControls}
          />
          <CSSTransition
            timeout={500}
            unmountOnExit
            mountOnEnter
            in={videoOverlay}
            classNames="fade"
          >
            <div className={styles.videoControls}>
              <img
                src={closeIcon}
                alt="close icon"
                onClick={closeVideo}
                className={styles.closeIcon}
              />
              <img
                src={rewindIcon}
                alt="rewind icon"
                onClick={restartVideo}
                className={styles.rewindIcon}
              />
              {pauseVideo ? (
                <img
                  src={playIcon}
                  alt="play icon"
                  className={styles.playIcon}
                  onClick={togglePause}
                />
              ) : (
                <img
                  src={pauseIcon}
                  alt="pause icon"
                  className={styles.pauseIcon}
                  onClick={togglePause}
                />
              )}
              {muteVideo ? (
                <img
                  src={unmuteIcon}
                  alt="unmute icon"
                  className={styles.unmuteIcon}
                  onClick={toggleMute}
                />
              ) : (
                <img
                  src={muteIcon}
                  alt="mute icon"
                  className={styles.muteIcon}
                  onClick={toggleMute}
                />
              )}
            </div>
          </CSSTransition>
        </>
      ) : (
        <>
          <img
            src={videoPlaceholder}
            alt=""
            onClick={() => setPlayVideo(true)}
            className={styles.videoPlaceholder}
          />
          <img
            src={playIconOverlay}
            alt=""
            className={styles.playIconOverlay}
          />
        </>
      )}
    </div>
  );
};
export default InPlaceVideo;
