import { Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// Pages
import Home from "./pages/Home/Home";
import Car from "./pages/Car/Car";
import Team from "./pages/Team/Team";
import Season from "./pages/Season/Season";
import Drivers from "./pages/Drivers/Drivers";
import Involved from "./pages/Involved/Involved";
import Fw45Facts from "./pages/Fw45Facts/Fw45Facts";
import Tyres from "./pages/Tyres/Tyres";
import Aero from "./pages/Aero/Aero";
import PowerUnit from "./pages/PowerUnit/PowerUnit";
import Safety from "./pages/Safety/Safety";
import SteeringWheel from "./pages/SteeringWheel/SteeringWheel";
import Partners from "./pages/Partners/Partners";
import Crew from "./pages/Crew/Crew";
import Leadership from "./pages/Leadership/Leadership";
import SeasonStandings from "./pages/SeasonStandings/SeasonStandings";
import CurrentRace from "./pages/CurrentRace/CurrentRace";
import Driver from "./pages/Driver/Driver";
import PopupShop from "./pages/PopupShop/PopupShop";
import GuideToF1 from "./pages/GuideToF1/GuideToF1";
import WilliamsExperience from "./pages/WilliamsExperience/WilliamsExperience";
import Download from "./pages/Download/Download";

function App() {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="slide" timeout={500}>
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/car" element={<Car />} />
          <Route path="/team" element={<Team />} />
          <Route path="/season" element={<Season />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/drivers/:name" element={<Driver />} />
          <Route path="/involved" element={<Involved />} />
          <Route path="/facts" element={<Fw45Facts />} />
          <Route path="/tyres" element={<Tyres />} />
          <Route path="/aero" element={<Aero />} />
          <Route path="//powerunit" element={<PowerUnit />} />
          <Route path="/safety" element={<Safety />} />
          <Route path="/steeringwheel" element={<SteeringWheel />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/pitcrew" element={<Crew />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/standings" element={<SeasonStandings />} />
          <Route path="/currentrace" element={<CurrentRace />} />
          <Route path="/popupshop" element={<PopupShop />} />
          <Route path="/guidetof1" element={<GuideToF1 />} />
          <Route path="/williamsexperience" element={<WilliamsExperience />} />
          <Route path="/download" element={<Download />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
