import backgroundImage from "./assets/images/Steering_Wheel_Background.webp";
import styles from "./assets/css/SteeringWheel.module.css";
import TopNav from "../../components/TopNav/TopNav";
import { useState } from "react";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

const SteeringWheel = () => {
  // Array of buttons, including their positions, which is then filtered to only
  // include buttons that do not have the default "button #" name"
  const buttons = [
    // Left Buttons
    { name: "DRS", top: 680, left: 70, size: 100 },
    { name: "button 2", top: 772, left: 145, size: 100 },
    { name: "Neutral / Reverse", top: 790, left: 288, size: 100 },
    { name: "button 4", top: 861, left: 395, size: 100 },
    { name: "button 5", top: 925, left: 310, size: 100 },
    { name: "Ok / Enter", top: 970, left: 483, size: 100 },
    { name: "Downshift Paddle", top: 1040, left: 340, size: 100 },
    { name: "Brake Balance (Rear)", top: 1095, left: 530, size: 100 },
    { name: "button 9", top: 1150, left: 425, size: 100 },
    { name: "button 10", top: 1220, left: 530, size: 100 },
    { name: "Brake Warming / Anti Overtake", top: 1325, left: 510, size: 100 },
    { name: "LH Clutch Paddle", top: 1480, left: 440, size: 100 },

    // Middle Buttons
    { name: "button 13", top: 1065, left: 652, size: 225 },
    { name: "Engine Mode", top: 1305, left: 660, size: 225 },
    { name: "Car Setting Option", top: 1220, left: 965, size: 190 },
    { name: "button 16", top: 1138, left: 961, size: 50 },
    { name: "Radio Channel Open Light", top: 1130, left: 1040, size: 50 },
    { name: "button 18", top: 1140, left: 1103, size: 50 },
    { name: "button 19", top: 1075, left: 1240, size: 225 },
    { name: "Tyre Option", top: 1309, left: 1238, size: 225 },

    // Right Buttons
    { name: "button 21", top: 1560, right: 600, size: 100 },
    { name: "RH Clutch Paddle", top: 1390, right: 400, size: 100 },
    { name: "button 23", top: 1362, right: 542, size: 100 },
    { name: "button 24", top: 1240, right: 568, size: 100 },
    { name: "button 25", top: 1190, right: 470, size: 100 },
    { name: "Brake Balance (Front)", top: 1105, right: 557, size: 100 },
    { name: "Upshift Paddle", top: 1050, right: 390, size: 100 },
    { name: "Radio", top: 980, right: 510, size: 100 },
    { name: "button 29", top: 950, right: 330, size: 100 },
    { name: "button 30", top: 880, right: 417, size: 100 },
    { name: "Pitlane Speed Limiter", top: 805, right: 312, size: 100 },
    { name: "Puncture Mode", top: 800, right: 170, size: 100 },
    { name: "Overtake", top: 680, right: 110, size: 100 },
  ].filter((button) => !button.name.startsWith("button"));

  const [activeButton, setActiveButton] = useState(null);
  const [buttonLabel, setButtonLabel] = useState("PRESS A BUTTON");
  const [initialAnimationEnded, setInitialAnimationEnded] = useState(false);
  const [numButtonsFinishedAnimating, setNumButtonsFinishedAnimating] =
    useState(0);

  const handleInitialAnimationEnd = () => {
    setNumButtonsFinishedAnimating((currentValue) => currentValue + 1);
    if (numButtonsFinishedAnimating < buttons.length - 1) return;
    setInitialAnimationEnded(true);
  };

  const handleClick = (e) => {
    setActiveButton(Number(e.target.id));
    setButtonLabel(buttons[e.target.id].name);
  };

  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <div className={styles.backgroundContainer}>
        <img src={backgroundImage} alt="" className={styles.backgroundImage} />
      </div>
      <div className={styles.headingContainer}>
        <h1>Steering</h1>
        <h1 className={styles.blueText}>Wheel</h1>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.steeringWheelButtons}>
          {buttons &&
            buttons.map((button, index) => (
              <button
                type="button"
                key={index}
                id={index}
                className={`${styles.button} ${styles.buttonFlicker} ${
                  activeButton === index ? styles.activeButton : ""
                }
                ${initialAnimationEnded ? styles.buttonBlink : ""}`}
                style={{
                  top: `${button.top}px`,
                  left: `${button.left}px`,
                  right: `${button.right}px`,
                  height: `${button.size}px`,
                  animationDelay: `${1.25 + index / buttons.length}s`,
                }}
                onBlur={() => {
                  setActiveButton(null);
                  setButtonLabel("PRESS A BUTTON");
                }}
                onAnimationEnd={(e) => {
                  e.target.style.animationDelay = null;
                  handleInitialAnimationEnd();
                }}
                onClick={handleClick}
              />
            ))}
        </div>
        {
          <div className={styles.selectorContainer}>
            <div className={styles.textContainer}>
              <h3>{buttonLabel}</h3>
            </div>
          </div>
        }
      </div>
      <TempBackButton />
    </section>
  );
};

export default SteeringWheel;
