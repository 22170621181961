import { useEffect, useState } from "react";
import styles from "./assets/css/ScrollDown.module.css";
import downArrow from "./assets/images/downward-williams-arrow.svg";

const ScrollDown = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      const toggle =
        window.pageYOffset + window.innerHeight < document.body.scrollHeight;

      setIsVisible(toggle);
    };

    window.addEventListener("scroll", toggleVisibility);

    window.scrollTo({ top: 0 });
    return () => {
      window.addEventListener("scroll", toggleVisibility);
    };
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    setIsVisible(
      window.pageYOffset + window.innerHeight < document.body.scrollHeight
    );
  });

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    window.scrollTo({
      top: currentPosition + 3000,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`${styles.scrollContainer} ${
        isVisible ? styles.show : styles.hide
      }`}
    >
      <div className={`${styles.scrollIcon}`}>
        <img
          src={downArrow}
          alt=""
          className={styles.bounce}
          onClick={handleScroll}
        />
      </div>
    </div>
  );
};
export default ScrollDown;
