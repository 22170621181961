import styles from "./assets/css/TopNav.module.css";
import navBackArrow from "./assets/images/Nav-back-arrow.webp";
import navText from "./assets/images/Nav-text.webp";
import { useNavigate } from "react-router-dom";

const TopNav = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <nav className={styles.nav}>
      <img
        src={navBackArrow}
        className={styles.backArrow}
        alt="navigation back arrow"
        onClick={goBack}
      />
      <img
        src={navText}
        className={styles.navText}
        alt="williams racing logo"
        onClick={goHome}
      />
    </nav>
  );
};

export default TopNav;
