import TopNav from "../../components/TopNav/TopNav";
import backgroundImage from "./assets/images/Tyres_Background.webp";
import styles from "./assets/css/Tyres.module.css";
import { CSSTransition } from "react-transition-group";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Large Tyres
import softTyre from "./assets/images/soft-tyre.webp";
import mediumTyre from "./assets/images/medium-tyre.webp";
import hardTyre from "./assets/images/hard-tyre.webp";
import intermediateTyre from "./assets/images/intermediate-tyre.webp";
import wetTyre from "./assets/images/full-wet-tyre.webp";

// Tyre Icons
import iconSoft from "./assets/images/softTyreIcon.webp";
import iconMedium from "./assets/images/mediumTyreIcon.webp";
import iconHard from "./assets/images/hardTyreIcon.webp";
import iconIntermediate from "./assets/images/intermediateTyreIcon.webp";
import iconWet from "./assets/images/wetTyreIcon.webp";
import { useState } from "react";

const Tyres = () => {
  const tyreInfo = [
    {
      name: "p zero red soft",
      content:
        "The P Zero Red soft (C3, C4 or C5) is used for the opposite needs: it reaches the desired temperature more quickly, guarantees more ground contact and better grip.\n\nFor this reason it is the compound with which the lowest times are recorded and therefore it is usually used in the decisive stages of the qualifying rounds.\n\nThe flip side of the coin is clearly durability: the softer the compound, the more its durability will be reduced.",
      image: softTyre,
    },
    {
      name: "p zero yellow medium",
      content:
        "The P Zero Yellow medium (C2, C3 or C4) is the most versatile solution in the nomination, mid-way between hard and soft that can be chosen for the moments of the race in which a balance between durability and speed is required.",
      image: mediumTyre,
    },
    {
      name: "p zero white hard",
      content:
        "The P Zero White hard (which can be selected by Pirelli between C0 and C3) is the one opted for to race the most laps because it is subject to less deterioration and consequently offers greater durability.\n\nIt will be the preferred compound if the circuit has a very abrasive asphalt, if the temperatures are particularly high and if there are numerous areas along the track where the forces to which the tyres are subjected are high.",
      image: hardTyre,
    },
    {
      name: "intermediate",
      content:
        "The intermediates are the most versatile of the rain tyres. They can be used on a wet track with no standing water, as well as a drying surface.\n\nThe compound has been designed to have a wide working range, guaranteeing a wide crossover window both with the slicks and the full wets.",
      image: intermediateTyre,
    },
    {
      name: "full wet",
      content:
        "The full wet tyres are the most effective for heavy rain, capable of dispersing impressive quantities of water. But if it rains heavily, visibility rather than grip causes issues, leading to race stoppages on occasions.\n\nThe profile delivers increased resistance to aquaplaning, which gives the tyre more grip in heavy rain.",
      image: wetTyre,
    },
  ];

  const tyreIcons = [iconSoft, iconMedium, iconHard, iconIntermediate, iconWet];
  const [activeTyreIndex, setActiveTyreIndex] = useState(0);

  const handleTyreSelect = (index) => {
    setActiveTyreIndex(index);
  };

  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <img src={backgroundImage} alt="" className={styles.backgroundImage} />
      <div className={styles.pageHeader}>
        <h1>Tyres</h1>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.tyreContainer}>
          {tyreInfo &&
            tyreInfo.map((tyre, index) => (
              <CSSTransition
                timeout={500}
                in={activeTyreIndex === index ? true : false}
                mountOnEnter
                unmountOnExit
                classNames="fade"
                key={index}
              >
                <div className={styles.tyreInfo}>
                  <img src={tyre.image} alt="" height={1400} />
                  <div className={styles.tyreText}>
                    <h2>{tyre.name}</h2>
                    <p>{tyre.content}</p>
                  </div>
                </div>
              </CSSTransition>
            ))}
        </div>
        <div className={styles.tyreIcons}>
          {tyreIcons &&
            tyreIcons.map((icon, index) => (
              <div
                className={`${styles.tyre} ${
                  activeTyreIndex === index ? styles.activeTyre : ""
                }`}
                key={index}
                onClick={() => handleTyreSelect(index)}
              >
                <img src={icon} alt="" />
              </div>
            ))}
        </div>
      </div>
      <TempBackButton />
    </section>
  );
};

export default Tyres;
