import TopNav from "../../components/TopNav/TopNav";
import styles from "./assets/css/Fw45Facts.module.css";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

import backgroundImage from "./assets/images/FW45FactsBackground.webp";
import FlipCards from "../../components/FlipCards/FlipCards";

// Images
import chassisImage from "./assets/images/chassisContruction.webp";
import frontSuspensionImage from "./assets/images/fronSuspension.webp";
import readSuspensionImage from "./assets/images/rearSuspension.webp";
import transmissionImage from "./assets/images/transmission.webp";
import clutchImage from "./assets/images/clutch.webp";
import dampersImage from "./assets/images/dampers.webp";
import wheelsImage from "./assets/images/wheels.webp";
import tyresImage from "./assets/images/tyres.webp";
import breakSystemImage from "./assets/images/breakSystem.webp";
import electronicsImage from "./assets/images/electronicsSystems.webp";
import coolingSystemImage from "./assets/images/coolingSystem.webp";
import cockpitImage from "./assets/images/cockpit.webp";
import powerUnitImage from "./assets/images/powerUnit.webp";
import fuelInjectionImage from "./assets/images/fuelInjection.webp";
import energyRecoverySystemImage from "./assets/images/energyRecoverySystem.webp";
import weightImage from "./assets/images/weight.webp";
import dimensionsImage from "./assets/images/dimensions.webp";

// Icons
import brakesIcon from "./assets/images/icons/brakes.svg";
import chassisIcon from "./assets/images/icons/chassis.svg";
import clutchIcon from "./assets/images/icons/clutch.svg";
import cockpitIcon from "./assets/images/icons/cockpit.svg";
import coolingIcon from "./assets/images/icons/cooling.svg";
import dampersIcon from "./assets/images/icons/dampers.svg";
import dimensionsIcon from "./assets/images/icons/dimensions.svg";
import electronicsIcon from "./assets/images/icons/electronics.svg";
import energyIcon from "./assets/images/icons/energy.svg";
import fuelIcon from "./assets/images/icons/fuel.svg";
import wheelIcon from "./assets/images/icons/wheel.svg";
import powerUnitIcon from "./assets/images/icons/powerUnit.svg";
import suspensionIcon from "./assets/images/icons/suspension.svg";
import transmissionIcon from "./assets/images/icons/transmission.svg";
import tyresIcon from "./assets/images/icons/tyres.svg";
import weightIcon from "./assets/images/icons/weight.svg";
import ScrollDown from "../../components/ScrollDown/ScrollDown";

const Fw45Facts = () => {
  const cardsData = [
    {
      image: chassisImage,
      icon: chassisIcon,
      backText: "Chassis construction",
      frontText:
        "Monocoque construction laminated from carbon epoxy and honeycomb surpassing FIA impact and strength requirements",
    },
    {
      image: frontSuspensionImage,
      icon: suspensionIcon,
      backText: "Front Suspension",
      frontText:
        "Double wishbone, push-rod activated springs and anti-\nroll bar",
    },
    {
      image: readSuspensionImage,
      icon: suspensionIcon,
      backText: "Rear Suspension",
      frontText:
        "Double wishbone, pull- rod activated springs and anti-\nroll bar",
    },
    {
      image: transmissionImage,
      icon: transmissionIcon,
      backText: "Transmission",
      frontText:
        "Mercedes-AMG F1: eight-speed forward, one reverse unit with carbon fibre maincase. Sequential, semi-automatic, hydraulic activation gear selection",
    },
    {
      image: clutchImage,
      icon: clutchIcon,
      backText: "Clutch",
      frontText: "Carbon\nmulti-plate",
    },
    {
      image: dampersImage,
      icon: dampersIcon,
      backText: "Dampers",
      frontText: "Hydraulic",
    },
    {
      image: wheelsImage,
      icon: wheelIcon,
      backText: "Wheels",
      frontText: "F1 Standard\nSupply Component",
    },
    {
      image: tyresImage,
      icon: tyresIcon,
      backText: "Tyres",
      frontText: "Pirelli Fronts:\n305/720-18\n\nRears:\n405/720-18",
    },
    {
      image: breakSystemImage,
      icon: brakesIcon,
      backText: "Brake System",
      frontText:
        "Brembo 6 piston front and rear callipers, with carbon discs and pads",
    },
    {
      image: electronicsImage,
      icon: electronicsIcon,
      backText: "Electronics System",
      frontText: "FIA SECU\nstandard\nelectronic\ncontrol unit",
    },
    {
      image: coolingSystemImage,
      icon: coolingIcon,
      backText: "Cooling System",
      frontText: "Aluminium oil, water, charge\nair, ERS and gearbox coolers",
    },
    {
      image: cockpitImage,
      icon: cockpitIcon,
      backText: "Cockpit",
      frontText:
        "OMP Six-point driver safety harness with 75mm shoulder straps & system, removable automatically formed carbon\nfibre seat",
    },
    {
      image: powerUnitImage,
      icon: powerUnitIcon,
      backText: "Power Unit",
      frontText: "Mercedes-AMG\nF1 M14 E\nPERFORMANCE",
    },
    {
      image: fuelInjectionImage,
      icon: fuelIcon,
      backText: "Fuel Injection",
      frontText:
        "High-pressure direct injection (max 500 bar, one injector/cylinder), pressure charging single-stage compressor and exhaust turbine on a common shaft, max rpm exhaust turbine 125,000 rpm",
    },
    {
      image: energyRecoverySystemImage,
      icon: energyIcon,
      backText: "Energy Recovery System",
      frontText: "Mercedes-AMG HPP",
    },
    {
      image: weightImage,
      icon: weightIcon,
      backText: "Weight",
      frontText: "798kg\n(FIA Minimum)",
    },
    {
      image: dimensionsImage,
      icon: dimensionsIcon,
      backText: "Dimensions",
      frontText: "Overall height:\n970mm\n\nOverall width:\n2000mm",
    },
  ];
  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <div className={styles.pageHeader}>
        <div>
          <img
            src={backgroundImage}
            alt=""
            className={styles.backgroundImage}
          />
          <div className={styles.headingContainer}>
            <h1>FW45</h1>
            <h2 className={styles.blueText}>FACTS</h2>
          </div>
        </div>
      </div>
      <div className={styles.pageContent}>
        <FlipCards cards={cardsData} />
      </div>
      <ScrollDown />
      <TempBackButton />
    </section>
  );
};

export default Fw45Facts;
