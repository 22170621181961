import TopNav from "../../components/TopNav/TopNav";
import headerImage from "./assets/images/guideToF1HeaderImage.webp";
import styles from "./assets/css/GuideToF1.module.css";
import TempBackButton from "../../components/TempBackButton/TempBackButton";
import InPlaceVideo from "../../components/InPlaceVideo/InPlaceVideo";
import placeHolderVideoImage from "./assets/images/placeholder.webp";
import guideToF1Video from "./assets/videos/guide_to_f1.mp4";

const GuideToF1 = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <img className={styles.headerImage} src={headerImage} alt="" />
        <div className={styles.titleContainer}>
          <h1>Guide</h1>
          <h1 className={styles.blueText}>To F1</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <InPlaceVideo
          videoPlaceholder={placeHolderVideoImage}
          videoSource={guideToF1Video}
        />
        <div className={styles.textContent}>
          <h2 className={styles.blueText}>
            A Beginner's Guide To Formula 1 in 2023
          </h2>
          <p>
            Are you new to the fast-paced, thrilling world of Formula 1? Welcome
            along!
          </p>
          <p>
            Do you simply need a refresher ahead of this weekend's action? Well,
            you're in the right place!
          </p>
          <p>
            Enjoy our Beginner's Guide to Formula 1 in 2023, it will get you
            fully up to speed with everything you need to know about the
            pinnacle of motorsport this season.
          </p>
        </div>
      </div>
      <TempBackButton />
    </div>
  );
};
export default GuideToF1;
