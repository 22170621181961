import TopNav from "../../components/TopNav/TopNav";
import backgroundImage from "./assets/images/Our_Partners.webp";
import styles from "./assets/css/Partners.module.css";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Partner Logos
import acronis from "./assets/images/logos/acronis.webp";
import bermont from "./assets/images/logos/bermont.webp";
import broadcom from "./assets/images/logos/broadcom.webp";
import ccc from "./assets/images/logos/ccc.webp";
import dorilton from "./assets/images/logos/dorilton.webp";
import dtex from "./assets/images/logos/dtex.webp";
import duracell from "./assets/images/logos/duracell.webp";
import financialTimes from "./assets/images/logos/financialTimes.webp";
import gulf from "./assets/images/logos/gulf.webp";
import jumeirah from "./assets/images/logos/jumeirah.webp";
import kraken from "./assets/images/logos/kraken.webp";
import life from "./assets/images/logos/life.webp";
import michelob from "./assets/images/logos/michelob.webp";
import omp from "./assets/images/logos/omp.webp";
import pirelli from "./assets/images/logos/pirelli.webp";
import ppg from "./assets/images/logos/ppg.webp";
import purestream from "./assets/images/logos/purestream.webp";
import sia from "./assets/images/logos/sia.webp";
import stephens from "./assets/images/logos/stephens.webp";
import umbro from "./assets/images/logos/umbro.webp";
import zeiss from "./assets/images/logos/zeiss.webp";
import myProtein from "./assets/images/logos/myProtein.webp";
import ingenuity from "./assets/images/logos/ingenuity.png";

const Partners = () => {
  const partners = [
    { logo: myProtein },
    { logo: gulf },
    { logo: duracell },
    { logo: kraken },
    { logo: stephens },
    { logo: dorilton },
    { logo: acronis },
    { logo: michelob },
    { logo: jumeirah },
    { logo: ingenuity },
    { logo: financialTimes },
    { logo: broadcom },
    { logo: bermont },
    { logo: purestream },
    { logo: pirelli },
    { logo: umbro },
    { logo: zeiss },
    { logo: ccc },
    { logo: ppg },
    { logo: omp },
    { logo: dtex },
    { logo: life },
    { logo: sia },
  ];

  return (
    <section className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <div className={styles.backgroundContainer}>
          <img
            src={backgroundImage}
            className={styles.backgroundImage}
            alt=""
          />
        </div>
        <div className={styles.headingContainer}>
          <h1>Our</h1>
          <h1 className={styles.blueText}>Partners</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.partners}>
          {partners &&
            partners.map((partner, index) => (
              <div className={styles.partner} key={index}>
                <img src={partner.logo} alt="" />
              </div>
            ))}
        </div>
      </div>
      <ScrollDown />
      <TempBackButton />
    </section>
  );
};

export default Partners;
