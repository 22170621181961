import backgroundImage from "./assets/images/HomeBackground.webp";
import backgroundSmoke from "./assets/images/smokeWhiteFog.webp";
import textImage from "./assets/images/WilliamsRacingText.svg";
import NavCard from "../../components/NavCard/NavCard";

import styles from "./assets/css/Home.module.css";

// NavCard Images
import carImage from "./assets/images/car.webp";
import teamImage from "./assets/images/team.webp";
import seasonImage from "./assets/images/season.webp";
import driversImage from "./assets/images/drivers.webp";
import involvedImage from "./assets/images/involved.webp";
import { useEffect, useRef, useState } from "react";

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [pulseNavCard, setPulseNavCard] = useState(false);
  const scrollContainer = useRef();
  const scrollMarker = useRef();

  useEffect(() => {
    if (!scrollContainer.current || !scrollMarker.current) return;
    scrollMarker.current.style.left = `${scrollPosition}%`;
  }, [scrollPosition]);

  const handleTouch = () => {
    setPulseNavCard(true);
    setTimeout(() => {
      setPulseNavCard(false);
    }, 5000);
  };

  return (
    <div className={styles.container}>
      <div className={styles.backgroundContainer} onClick={handleTouch}>
        <img
          height={466}
          width={1506}
          src={textImage}
          className={styles.textImage}
          alt="Williams Racing"
        />
        <img
          height={2893}
          width={2160}
          src={backgroundImage}
          className={styles.backgroundImage}
          alt="Williams F1 Car"
        />
        <img
          height={2893}
          width={2160}
          src={backgroundSmoke}
          className={styles.backgroundSmoke}
          alt="Background smoke effect"
        />
        <div className={styles.splitTextContainer}>
          <div className={styles.splitText}>
            TOUCH SCREEN TO BEGIN
            <span className={styles.mask}>
              <span>TOUCH SCREEN TO BEGIN</span>
            </span>
            <span className={styles.mask}>
              <span>TOUCH SCREEN TO BEGIN</span>
            </span>
          </div>
        </div>
      </div>
      <div
        className={styles.navigation}
        ref={scrollContainer}
        onScroll={(e) => {
          setScrollPosition(
            Math.min(
              Math.max(
                (e.target.scrollLeft / (e.target.scrollWidth / 2)) * 89.75,
                0
              ),
              100
            )
          );
        }}
      >
        <NavCard
          height={622}
          width={801}
          image={carImage}
          whiteText={"FW45"}
          blueText={"2023 CAR"}
          linkTo={"/car"}
          pulse={pulseNavCard}
        />
        <NavCard
          height={622}
          width={801}
          image={teamImage}
          whiteText={"WILLIAMS"}
          blueText={"TEAM"}
          linkTo={"/team"}
          pulse={pulseNavCard}
        />
        <NavCard
          height={622}
          width={801}
          image={seasonImage}
          whiteText={"2023"}
          blueText={"SEASON"}
          linkTo={"/season"}
          pulse={pulseNavCard}
        />
        <NavCard
          height={622}
          width={801}
          image={driversImage}
          whiteText={"WILLIAMS"}
          blueText={"DRIVERS"}
          linkTo={"/drivers"}
          pulse={pulseNavCard}
        />
        <NavCard
          height={622}
          width={801}
          image={involvedImage}
          whiteText={"GET"}
          blueText={"INVOLVED"}
          linkTo={"/involved"}
          pulse={pulseNavCard}
        />
      </div>
      <div className={styles.scrollBarContainer}>
        <div
          className={styles.scrollBar}
          onMouseDown={(e) => {
            const clickPosX = e.clientX;
            scrollContainer.current.scrollTo({
              left: Math.round(((clickPosX - 216) / 2160) * 3000),
              behavior: "smooth",
            });
          }}
          onTouchEnd={(e) => {
            const clickPosX = e.changedTouches[0].clientX;
            scrollContainer.current.scrollTo({
              left: Math.round(((clickPosX - 216) / 2160) * 3000),
              behavior: "smooth",
            });
          }}
        >
          <div className={styles.scrollMarker} ref={scrollMarker}></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
