import RootPage from "../../components/RootPage/RootPage";
import TempBackButton from "../../components/TempBackButton/TempBackButton";
import seasonPageBackground from "./assets/images/seasonMenuBackgroundImage.webp";

import currentRace from "./assets/images/abuDhabi/CurrentRace.webp";
import seasonStandings from "./assets/images/SeasonStandings.webp";

const Season = () => {
  const seasonCardImages = [
    { card: seasonStandings, linkTo: "/standings" },
    { card: currentRace, linkTo: "/currentrace" },
  ];

  return (
    <>
      <RootPage
        backgroundImage={seasonPageBackground}
        cardImages={seasonCardImages}
        cardImageSize={{ height: 1498, width: 1864 }}
        whiteText={"2023"}
        blueText={"SEASON"}
      />
      <TempBackButton />
    </>
  );
};

export default Season;
