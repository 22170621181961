import { useEffect, useRef, useState } from "react";
import styles from "./assets/css/VerticalVideoBox.module.css";
import closeIcon from "./assets/images/X.svg";
import muteIcon from "./assets/images/mute.svg";
import pauseIcon from "./assets/images/pause.svg";
import playIcon from "./assets/images/play.svg";
import rewindIcon from "./assets/images/rewind.svg";
import unmuteIcon from "./assets/images/unmute.svg";
import { CSSTransition } from "react-transition-group";

const VerticalVideoBox = ({ videoSource, handleVideoEnded }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [pauseVideo, setPauseVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [videoOverlay, setVideoOverlay] = useState(false);
  const videoRef = useRef();

  const toggleMute = () => {
    setMuteVideo((currentState) => !currentState);
  };

  const togglePause = () => {
    setPauseVideo((currentState) => !currentState);
  };

  const toggleVideoControls = () => {
    setVideoOverlay((currentState) => !currentState);
    setTimeout(() => {
      setVideoOverlay((currentState) => !currentState);
    }, 3000);
  };

  const restartVideo = () => {
    videoRef.current.currentTime = 0;
  };

  useEffect(() => {
    if (!videoRef.current) return;
    pauseVideo ? videoRef.current.pause() : videoRef.current.play();
  }, [pauseVideo]);

  useEffect(() => {
    if (videoSource) {
      setPlayVideo(true);
    } else {
      setPlayVideo(false);
    }
  }, [videoSource]);

  return (
    <CSSTransition
      timeout={500}
      unmountOnExit
      mountOnEnter
      in={playVideo}
      classNames="fade"
    >
      <div className={styles.videoBox}>
        <button className={styles.closeButton}>
          <img src={closeIcon} alt="" onClick={handleVideoEnded} />
        </button>
        <div className={styles.videoContainer}>
          <video
            ref={videoRef}
            src={videoSource}
            muted={muteVideo}
            height={1920}
            autoPlay
            onEnded={handleVideoEnded}
            onClick={toggleVideoControls}
            onPlay={() => setPauseVideo(false)}
          />
          <CSSTransition
            timeout={500}
            unmountOnExit
            mountOnEnter
            in={videoOverlay}
            classNames="fade"
          >
            <div className={styles.videoControls}>
              <img
                src={rewindIcon}
                alt="rewind icon"
                onClick={restartVideo}
                className={styles.rewindIcon}
              />
              {pauseVideo ? (
                <img
                  src={playIcon}
                  alt="play icon"
                  className={styles.playIcon}
                  onClick={togglePause}
                />
              ) : (
                <img
                  src={pauseIcon}
                  alt="pause icon"
                  className={styles.pauseIcon}
                  onClick={togglePause}
                />
              )}
              {muteVideo ? (
                <img
                  src={unmuteIcon}
                  alt="unmute icon"
                  className={styles.unmuteIcon}
                  onClick={toggleMute}
                />
              ) : (
                <img
                  src={muteIcon}
                  alt="mute icon"
                  className={styles.muteIcon}
                  onClick={toggleMute}
                />
              )}
            </div>
          </CSSTransition>
        </div>
        <button
          onClick={handleVideoEnded}
          className={styles.accessibleCloseButton}
        >
          <img src={closeIcon} alt="" />
        </button>
      </div>
    </CSSTransition>
  );
};
export default VerticalVideoBox;
