import { useState } from "react";
import styles from "./assets/css/FlipCard.module.css";

const FlipCard = ({ image, icon, backText, frontHeading, frontText }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    setTimeout(() => {
      setIsFlipped(false);
    }, 7000);
  };
  return (
    <div
      className={`${styles.flipCardInner} ${isFlipped ? styles.flipped : ""}`}
      onClick={handleFlip}
    >
      <div className={styles.flipCardBack}>
        {image && <img src={image} alt="" className={styles.image} />}
        {icon && <img src={icon} alt="" className={styles.icon} />}
        {backText && <p>{backText}</p>}
      </div>
      <div className={styles.flipCardFront}>
        {frontHeading ? (
          <div className={styles.cardWithHeading}>
            <h2>{frontHeading}</h2>
            <p>{frontText}</p>
          </div>
        ) : (
          frontText && (
            <div className={styles.cardWithoutHeading}>
              <p>{frontText}</p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FlipCard;
