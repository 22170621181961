import styles from "./assets/css/SeasonStandings.module.css";
import backgroundImage from "./assets/images/SeasonStandingsBackground.webp";
import TopNav from "../../components/TopNav/TopNav";
import { CSSTransition } from "react-transition-group";
import { useState } from "react";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Team Logos
import alfaRomeoLogo from "./assets/images/constructorLogos/alfaRomeoLogo.webp";
import alphatauriLogo from "./assets/images/constructorLogos/alphatauriLogo.webp";
import alpineLogo from "./assets/images/constructorLogos/alpineLogo.webp";
import astonMartinLogo from "./assets/images/constructorLogos/astonMartinLogo.webp";
import ferrariLogo from "./assets/images/constructorLogos/ferrariLogo.webp";
import haasLogo from "./assets/images/constructorLogos/haasLogo.webp";
import mclarenLogo from "./assets/images/constructorLogos/mclarenLogo.webp";
import mercedesLogo from "./assets/images/constructorLogos/mercedesLogo.webp";
import redBullLogo from "./assets/images/constructorLogos/redBullLogo.webp";
import williamsLogo from "./assets/images/constructorLogos/williamsLogo.webp";

const SeasonStandings = () => {
  const [driverActive, setDriverActive] = useState(true);
  const [constructorActive, setConstructorActive] = useState(false);

  const handleDriverButton = () => {
    setConstructorActive(false);
    setDriverActive(true);
  };

  const handleConstructorButton = () => {
    setConstructorActive(true);
    setDriverActive(false);
  };

  const driverStandings = [
    {
      position: 1,
      driver: "Max Verstappen",
      team: "Red Bull Racing Honda RBPT",
      points: 549,
      logo: redBullLogo,
    },
    {
      position: 2,
      driver: "Sergio Perez",
      team: "Red Bull Racing Honda RBPT",
      points: 273,
      logo: redBullLogo,
    },
    {
      position: 5,
      driver: "Fernando Alonso",
      team: "Aston Martin Aramco Mercedes",
      points: 200,
      logo: astonMartinLogo,
    },
    {
      position: 3,
      driver: "Lewis Hamilton",
      team: "Mercedes",
      points: 232,
      logo: mercedesLogo,
    },
    {
      position: 4,
      driver: "Carlos Sainz",
      team: "Ferrari",
      points: 200,
      logo: ferrariLogo,
    },
    {
      position: 7,
      driver: "Charles Leclerc",
      team: "Ferrari",
      points: 188,
      logo: ferrariLogo,
    },
    {
      position: 8,
      driver: "George Russell",
      team: "Mercedes",
      points: 160,
      logo: mercedesLogo,
    },
    {
      position: 10,
      driver: "Lance Stroll",
      team: "Aston Martin Aramco Mercedes",
      points: 73,
      logo: astonMartinLogo,
    },
    {
      position: 12,
      driver: "Esteban Ocon",
      team: "Alpine Renault",
      points: 58,
      logo: alpineLogo,
    },
    {
      position: 6,
      driver: "Lando Norris",
      team: "McLaren Mercedes",
      points: 195,
      logo: mclarenLogo,
    },
    {
      position: 11,
      driver: "Pierre Gasly",
      team: "Alpine Renault",
      points: 62,
      logo: alpineLogo,
    },
    {
      position: 16,
      driver: "Nico Hulkenberg",
      team: "Haas Ferrari",
      points: 9,
      logo: haasLogo,
    },
    {
      position: 13,
      driver: "Alexander Albon",
      team: "Williams Mercedes",
      points: 27,
      logo: williamsLogo,
    },
    {
      position: 9,
      driver: "Oscar Piastri",
      team: "McLaren Mercedes",
      points: 89,
      logo: mclarenLogo,
    },
    {
      position: 15,
      driver: "Valtteri Bottas",
      team: "Alfa Romeo Ferrari",
      points: 10,
      logo: alfaRomeoLogo,
    },
    {
      position: 18,
      driver: "Zhou Guanyu",
      team: "Alfa Romeo Ferrari",
      points: 6,
      logo: alfaRomeoLogo,
    },
    {
      position: 14,
      driver: "Yuki Tsunoda",
      team: "Alphatauri Honda RBPT",
      points: 13,
      logo: alphatauriLogo,
    },
    {
      position: 19,
      driver: "Kevin Magnussen",
      team: "Haas Ferrari",
      points: 3,
      logo: haasLogo,
    },
    {
      position: 21,
      driver: "Logan Sargeant",
      team: "Williams Mercedes",
      points: 1,
      logo: williamsLogo,
    },
    {
      position: 20,
      driver: "Liam Lawson",
      team: "Alphatauri Honda RBPT",
      points: 2,
      logo: alphatauriLogo,
    },
    {
      position: 22,
      driver: "Nyck De Vries",
      team: "Alphatauri Honda RBPT",
      points: 0,
      logo: alphatauriLogo,
    },
    {
      position: 17,
      driver: "Daniel Riccardo",
      team: "Alphatauri Honda RBPT",
      points: 6,
      logo: alphatauriLogo,
    },
  ];

  const constructorStandings = driverStandings
    .reduce((acc, curr) => {
      const index = acc.findIndex((item) => item.team === curr.team);
      index > -1
        ? (acc[index].points += curr.points)
        : acc.push({
            team: curr.team,
            points: curr.points,
            logo: curr.logo,
          });
      return acc;
    }, [])
    .sort((a, b) => b.points - a.points);

  driverStandings.sort((a, b) => a.position - b.position);

  return (
    <section className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <div className={styles.backgroundContainer}>
          <img
            src={backgroundImage}
            className={styles.backgroundImage}
            alt=""
          />
        </div>
        <div className={styles.headingContainer}>
          <h1>Season</h1>
          <h1 className={styles.blueText}>Standings</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.buttons}>
          <button
            className={`${styles.button} ${driverActive ? styles.active : ""}`}
            type="button"
            onClick={handleDriverButton}
          >
            Driver Standings
          </button>
          <button
            className={`${styles.button} ${
              constructorActive ? styles.active : ""
            }`}
            type="button"
            onClick={handleConstructorButton}
          >
            Constructor Standings
          </button>
        </div>
        <CSSTransition
          timeout={500}
          classNames="fade"
          mountOnEnter
          unmountOnExit
          in={driverActive}
        >
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <th>Position</th>
                <th>Driver</th>
                <th>Team</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {driverStandings &&
                driverStandings.map((data, index) => (
                  <tr
                    key={index}
                    className={
                      data.team === "Williams Mercedes"
                        ? styles.highlight
                        : null
                    }
                  >
                    <td>{data.position}</td>
                    <td>{data.driver}</td>
                    <td>{data.team}</td>
                    <td>{data.points}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </CSSTransition>
        <CSSTransition
          timeout={500}
          classNames="fade"
          mountOnEnter
          unmountOnExit
          in={constructorActive}
        >
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <th>Position</th>
                <th className={styles.teamHeading}>Team</th>
                <th></th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {constructorStandings &&
                constructorStandings.map((data, index) => (
                  <tr
                    key={index}
                    className={
                      data.team === "Williams Mercedes"
                        ? styles.highlight
                        : null
                    }
                  >
                    <td>{index + 1}</td>
                    <td className={styles.logoTeam}>
                      <img
                        src={data.logo}
                        alt={`${data.team} logo`}
                        loading="lazy"
                      />
                    </td>
                    <td>{data.team}</td>
                    <td>{data.points}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </CSSTransition>
      </div>

      <TempBackButton />
    </section>
  );
};

export default SeasonStandings;
