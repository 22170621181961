import { Link } from "react-router-dom";
import styles from "./assets/css/Drivers.module.css";
import TopNav from "../../components/TopNav/TopNav";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

import alexalbon from "./assets/images/alexalbon.webp";
import alexalbonProfile from "./assets/images/alexalbonProfile.webp";
import alexalbonVideo from "./assets/videos/driver_alex_albon.mp4";
import alexalbonVideoPlaceholder from "./assets/images/alexalbonVideoPlaceholder.webp";

import logansargeant from "./assets/images/logansargeant.webp";
import logansargeantProfile from "./assets/images/logansargeantProfile.webp";
import locansargeantVideo from "./assets/videos/driver_logan_sargeant.mp4";
import locansargeantVideoPlaceholder from "./assets/images/logansargeantVideoPlaceholder.webp";

import zakosullivan from "./assets/images/zackosullivan.webp";
import zakosullivanProfile from "./assets/images/zackosullivanProfile.webp";
import zak1 from "./assets/images/Zak1.webp";
import zak2 from "./assets/images/Zak2.webp";
import zak3 from "./assets/images/Zak3.webp";
import zak4 from "./assets/images/Zak4.webp";
import zak5 from "./assets/images/Zak5.webp";

import olliegray from "./assets/images/olliegray.webp";
import olliegrayProfile from "./assets/images/olliegrayProfile.webp";
import ollie1 from "./assets/images/Ollie1.webp";
import ollie2 from "./assets/images/Ollie2.webp";
import ollie3 from "./assets/images/Ollie3.webp";

import francocolapinto from "./assets/images/francocolapinto.webp";
import francocolapintoProfile from "./assets/images/francocolapintoProfile.webp";
import Franco1 from "./assets/images/Franco1.webp";
import Franco2 from "./assets/images/Franco2.webp";
import Franco3 from "./assets/images/Franco3.webp";
import Franco4 from "./assets/images/Franco4.webp";
import Franco5 from "./assets/images/Franco5.webp";

import jamiechadwick from "./assets/images/jamiechadwick.webp";
import jamiechadwickProfile from "./assets/images/jamiechadwickProfile.webp";
import jamie1 from "./assets/images/Jamie1.webp";
import jamie2 from "./assets/images/Jamie2.webp";
import jamie3 from "./assets/images/Jamie3.webp";
import jamie4 from "./assets/images/Jamie4.webp";
import jamie5 from "./assets/images/Jamie5.webp";

import lukebrowning from "./assets/images/lukebrowning.webp";
import lukebrowningProfile from "./assets/images/lukebrowningProfile.webp";
import luke1 from "./assets/images/Luke1.webp";

import oleksandrbondarev from "./assets/images/oleksandrbondarev.webp";
import oleksandrbondarevProfile from "./assets/images/oleksandrbondarevProfile.webp";
import oleksandr1 from "./assets/images/oleksandr1.webp";
import oleksandr2 from "./assets/images/oleksandr2.webp";
import oleksandr3 from "./assets/images/oleksandr3.webp";

import liablock from "./assets/images/liablock.webp";
import liablockProfile from "./assets/images/liablockProfile.webp";
import liablock1 from "./assets/images/liablock1.webp";
import liablock2 from "./assets/images/liablock2.webp";
import liablock3 from "./assets/images/liablock3.webp";

import driversBackground from "./assets/images/DriversBackground.webp";
import academyBackground from "./assets/images/AcademyBackground.webp";

const Drivers = () => {
  const drivers = [
    {
      firstName: "Alex",
      lastName: "Albon",
      cardImage: alexalbon,
      profileImage: alexalbonProfile,
      url: "alexalbon",
      type: "williams driver",
      bio: "Beginning his motorsport journey at the age of eight, Alex started racing in the Honda Cadet class and quickly rose through the ranks, winning multiple titles along the way. He became a multiple champion at both the British and European levels, including claiming both the CIK-FIA World Cup and CIK-FIA European Championship in 2010 in the KF3 category.\n\nAlex graduated to single-seaters in 2012, spending three seasons competing in Formula Renault 2.0 and claiming a best finish of third overall in the 2014 Eurocup category. He subsequently stepped up to the FIA Formula 3 European Championship in 2015, finishing seventh overall and stepping on to the podium five times. A move to the GP3 Series followed for 2016, with Alex narrowly missing out on the title, ending the year runner-up to Charles Leclerc, having achieved four wins and seven podium finishes.\n\nTwo consecutive years in FIA Formula 2 saw Alex claim third in the championship in 2018, only beaten by George Russell and Lando Norris. That precipitated a move into Formula One, with Alex drafted into Scuderia Toro Rosso for 2019. A number of eye-catching performances saw him promoted to Red Bull Racing after just 12 races, where he remained for 2020, scoring two podium finishes and finishing the season seventh overall.",
      video: alexalbonVideo,
      videoPlaceholder: alexalbonVideoPlaceholder,
      images: [],
    },
    {
      firstName: "Logan",
      lastName: "Sargeant",
      cardImage: logansargeant,
      profileImage: logansargeantProfile,
      url: "logansargeant",
      type: "williams driver",
      bio: "Logan's career in motorsport began on the karting track, where he competed competitively from the age of eight. He quickly demonstrated his talent, winning the Karting Federation Junior championship in 2015, becoming the first American to win an FIA karting championship since 1978.\n\nIn 2016, Logan competed in the Formula 4 UAE Championship, securing second place overall by taking 15 podiums. The following year, the American joined Carlin Racing to run the F4 British Championship, finishing third and scoring two wins and 10 podiums along the way.\n\nIn 2018, Logan took on the Formula Renault Eurocup with R-ace GP, recording three wins and seven podiums to secure him fourth place in the championship.\n\n2019 saw Logan return to Carlin for his rookie season of the FIA Formula 3 Championship, in which he scored points on four occasions. He also joined the British team for his debut of the Macau Grand Prix, successfully navigating the challenging circuit to finish third overall.",
      video: locansargeantVideo,
      videoPlaceholder: locansargeantVideoPlaceholder,
      images: [],
    },

    {
      firstName: "Zak",
      lastName: "O'Sullivan",
      cardImage: zakosullivan,
      profileImage: zakosullivanProfile,
      url: "zakosullivan",
      type: "academy driver",
      bio: "Zak began karting at the age of eight and just two years later, in 2016, he claimed the rookie championship in British Super One and won the British GP Kartmasters Championship, before moving into European karting in 2017.\n\nA season in the CIK European OKJ Championship and the WSK European Championship resulted in 10th and 9th places respectively. The British driver demonstrated his progress the following season, bettering his 2017 effort to place 6th overall in the WSK European Championship. In 2018 Zak achieved a 2nd place overall in the OKJ DKM.\n\nIn 2019, Zak made a successful debut in the Ginetta Junior Championship, claiming three wins to become Rookie Champion and placing second overall.\n\nThe following year, Zak's maiden season in single seaters saw him finish as vice-champion in the 2020 F4 British Championship. His impressive campaign saw him claim nine wins and 18 podiums, missing out on the title by only four points.",
      video: "",
      images: [zak1, zak2, zak3, zak4, zak5],
    },
    {
      firstName: "Ollie",
      lastName: "Gray",
      cardImage: olliegray,
      profileImage: olliegrayProfile,
      url: "olliegray",
      type: "academy driver",
      bio: "Ollie's short motorsport career has already seen plenty of success, with the young racer from Surrey claiming multiple Motorsport UK and British Kart Championship titles at the X30 Junior level, before finishing as vice-champion in the 2019 IAME Euro Series.\n\nOllie then swapped karting for single-seater racing in 2021 where we enjoyed a stellar debut season in British F4.\n\nDriving for Fortec Motorsport, Ollie claimed two wins and two pole positions - all coming at the high-speed Thruxton circuit - and was consistently in the top eight throughout his rookie season.\n\nOllie then went on to compete in the final two rounds of the Italian F4 Championship.\n\nAhead of the 2022 season, Ollie became a member of the Williams Racing Driver Academy, and, as part of his role, will be fully immersed at Grove, working closely with departments across the business to support his development.",
      video: "",
      images: [ollie1, ollie2, ollie3],
    },
    {
      firstName: "Franco",
      lastName: "Colapinto",
      cardImage: francocolapinto,
      profileImage: francocolapintoProfile,
      url: "francocolapinto",
      type: "academy driver",
      bio: "Franco Colapinto started karting at the age of nine. He won the Argentinian Championship in 2016 and again in 2018.\n\nIn 2018 Franco begun his European single seat journey, entering the final round of the FIA Spanish F4 season, taking a win and a second place. Franco was scouted by Fernando Alonso's team FA Racing by Drivex, signing him up for the 2019 season.\n\nIn 2019, Franco achieved eleven victories, 13 podiums and 10 pole positions, being crowned the Spanish F4 champion. Following this, Franco started his 2020 season by securing his second single seater title after taking the rookie championship win in the 2020 Castrol Toyota Racing Series, adding another race win, a pole position and eight further podium finishes to his tally.\n\nFor the second leg of the 2020 season Franco Colapinto returned to Europe to compete in the Formula Renault Eurocup with MP Motorsport, taking two race wins and nine podiums to finish third in thechampionship as the highest ranked rookie.",
      video: "",
      images: [Franco1, Franco2, Franco3, Franco4, Franco5],
    },
    {
      firstName: "Jamie",
      lastName: "Chadwick",
      cardImage: jamiechadwick,
      profileImage: jamiechadwickProfile,
      url: "jamiechadwick",
      type: "academy driver",
      bio: "Jamie's interest in motorsport started on the karting track in 2010 where she achieved numerous wins and podiums at club level in the Junior series. She had a talent from a young age and went on to win the Ginetta Junior Scholarship in 2013.\n\nIn 2015, Jamie stepped up to the British GT Championship, driving an Aston Martin Racing V8 Vantage GT4 with Beechdean Motorsport. She secured the title in her first year and became the first female to win a British GT Championship. To mark this achievement, she was selected to be part of the Aston Martin Racing Driver Evolution Academy and invited by the British Racing Drivers' Club to become a 'Rising Star'.\n\nIn 2016, Jamie continued to drive in five rounds of the British GP Championship in Pro Am with 'Bake Off' TV celebrity Paul Hollywood, whilst completing her A-level studies at Cheltenham College. She also qualified for her Nordschleife Race Permit.\n\nIn 2017 she graduated into singles-seaters, competing in the British Formula 3 Championship. Jamie opened up her rookie season with a podium at Rockingham and finished ninth in the Championship.",
      video: "",
      images: [jamie1, jamie2, jamie3, jamie4, jamie5],
    },
    {
      firstName: "Luke",
      lastName: "Browning",
      cardImage: lukebrowning,
      profileImage: lukebrowningProfile,
      url: "lukebrowning",
      type: "academy driver",
      bio: "Luke is one of the rising stars of British motorsport having already seen success on the track in his junior career. The British driver took his first steps into motorsport at an early age in karting, before graduating into cars in 2016.\n\nFollowing a couple of impressive seasons in the Ginetta Junior Championship, Luke moved up to single-seaters in 2019 as he switched to British F4, crowned champion in his second season after delivering seven wins, 16 podiums and only finishing outside of the top six twice all year.\n\nLuke then turned his attention to the European racing scene in 2021 by competing in the ADAC F4 series. Luke had a successful 2022 season claiming the GB3 Championship with Hitech GP with five race victories and eight further podiums. The British racer also collected the prestigious Autosport BRDC Young Driver of the Year Award in 2022.\n\nHe currently competes for Hitech Pulse-Eight in Formula 3. Luke was announced as a Williams Racing Academy Driver at the end of April 2023.",
      video: "",
      images: [luke1],
    },
    {
      firstName: "Oleksandr",
      lastName: "Bondarev",
      cardImage: oleksandrbondarev,
      profileImage: oleksandrbondarevProfile,
      url: "oleksandrbondarev",
      type: "academy driver",
      bio: "Oleksandr has already demonstrated his talents behind the wheel with multiple karting achievements in his junior career. He was notably crowned the 2023 CIK-FIA Karting European Champion, becoming the first Ukrainian driver in the history of karting to win the championship. The 14-year-old began his journey in karting in 2014 at just five years old. By age seven, he became the youngest Ukrainian karting champion and winner of the Rotax Max Challenge Ukraine.\n\nSince then he has continued to climb the karting ladder with an impressive record of wins, podiums and pole positions. As part of the Williams Racing Driver Academy, Oleksandr will have the team’s full support and guidance in nurturing his talent and developing his skills throughout his progression in the junior categories of motorsport.\n\nThe rising star has recently joined PREMA’s karting team for the WSK Euro Series event in Franciacorta and will compete in this year’s FIA Karting World Championship that’s set to take place in October.",
      video: "",
      images: [oleksandr1, oleksandr2, oleksandr3],
    },
    {
      firstName: "Lia",
      lastName: "Block",
      cardImage: liablock,
      profileImage: liablockProfile,
      url: "liablock",
      type: "academy driver",
      bio: "Lia has grown up in the motorsport world and spent many of her childhood years in the paddock. She began racing karts at 11 years old and entered her first off-road racing series in the same year. Lia then started drifting cars at 13 years old and assumed drag-racing duties in the famous 1,400HP Mustang ‘Hoonicorn’ the year after.\n\nLia continued to build her skills in off-road racing and karting, becoming a front-runner in the Lucas Oil Off-Road Series’ lower classes by 2021 and taking multiple karting wins. She later took part in the final round of the 2021 American Rally Association Championship in the Oregon Trail Rally as a 15-year-old, for the Hoonigan Racing Division, marking her first national-level rally.\n\nLia undertook more regular rallying in 2022 as part of the ARA championship, a season which included her best finish at the Southern Ohio Forest Rally where she placed 9th overall and 3rd in the O2WD class. This proved an even more successful event for her in the 2023 season, achieving a 6th place finish overall and a victory in the O2WD class. With two rallies to go, Lia secured the 2023 ARA O2WD championship to become the youngest ARA champion in history.\n\n2023 has been a busy year for Lia, competing in Extreme E as part of Carl Cox Motorsport for the second half of the season alongside Timo Scheider. She has also begun her first season in Nitrocross NEXT, the junior category of the championship previously known as Nitro Rallycross.\n\nIn November 2023, it was announced that Lia will compete in the 2024 F1 Academy season with ART Grand Prix, as well as coming onboard as a Williams Racing Academy Driver for the 2023 season.",
      video: "",
      images: [liablock1, liablock2, liablock3],
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <TopNav />
      <section className={styles.williamsDrivers}>
        <div>
          <img
            src={driversBackground}
            alt=""
            className={styles.backgroundImage}
          />
        </div>
        <div className={styles.headingContainer}>
          <h1>
            Williams <span className={styles.blueText}>Drivers</span>
          </h1>
        </div>
        <div className={styles.driversContainer}>
          {drivers &&
            drivers.map((driver, index) =>
              driver.type === "williams driver" ? (
                <Link key={index} to={driver.url} state={{ driver }}>
                  <img
                    src={driver.cardImage}
                    alt={`${driver.name} profile`}
                    height={703}
                    width={1830}
                  />
                </Link>
              ) : (
                ""
              )
            )}
        </div>
      </section>
      <section className={styles.academyDrivers}>
        <div>
          <img
            src={academyBackground}
            alt=""
            className={styles.backgroundImage}
          />
        </div>
        <div className={styles.headingContainer}>
          <h1>
            Academy <span className={styles.blueText}>Drivers</span>
          </h1>
        </div>
        <div className={styles.driversContainer}>
          {drivers &&
            drivers.map((driver, index) =>
              driver.type === "academy driver" ? (
                <Link key={index} to={driver.url} state={{ driver }}>
                  <img
                    src={driver.cardImage}
                    alt={`${driver.name} profile`}
                    height={703}
                    width={1830}
                  />
                </Link>
              ) : (
                ""
              )
            )}
        </div>
      </section>
      <ScrollDown />
      <TempBackButton />
    </div>
  );
};

export default Drivers;
