import styles from "./assets/css/WilliamsExperience.module.css";
import headerImage from "./assets/images/williamsExperienceHeaderImage.webp";
import TopNav from "../../components/TopNav/TopNav";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import TempBackButton from "../../components/TempBackButton/TempBackButton";
import InPlaceVideo from "../../components/InPlaceVideo/InPlaceVideo";
import videoPlaceholder from "./assets/images/williamsExperienceVideoPlaceholder.webp";
import willamsExperienceVideo from "./assets/videos/experience_centre.mp4";
import williamsExperienceBackground from "./assets/images/williamsExperienceBackground.webp";
import esportsPublicEvents from "./assets/images/esportsPublicEvents.webp";
import heritageCollectionTour from "./assets/images/heritageCollectionTour.webp";
import activities from "./assets/images/activities.webp";
import educationalVisits from "./assets/images/educationalVisits.webp";
import eventsRoom from "./assets/images/eventsRooms.webp";
import eveningWilliamsHeritage from "./assets/images/eveningWilliamsHeritage.webp";
import raceDayExperiences from "./assets/images/raceDayExperiences.webp";
import privateDining from "./assets/images/privateDining.webp";

const WilliamsHq = () => {
  const experienceCards = [
    {
      image: eveningWilliamsHeritage,
      heading: "An Evening with Williams Heritage",
      text: "Enjoy a fantastic evening on site at Williams HQ as we take a look back at our racing heritage",
    },
    {
      image: raceDayExperiences,
      heading: "Race Day Experiences",
      text: "The very best of race day hospitality at the Williams Experience Centre",
    },
    {
      image: esportsPublicEvents,
      heading: "Esport Public Events",
      text: "Our Esports Lounge Simulators are now available for public bookings!",
    },
    {
      image: heritageCollectionTour,
      heading: "Heritage Collection Tour",
      text: "An escorted tour of the largest private collection of Formula 1 cars in the world",
    },
    {
      image: eventsRoom,
      heading: "Event Rooms",
      text: "7 flexible event rooms catering from 2 to 350 guests",
    },
    {
      image: activities,
      heading: "Activities",
      text: "Even more to do at the Williams Experience Centre",
    },
    {
      image: privateDining,
      heading: "Private Dining",
      text: "5-star catering and service available",
    },
    {
      image: educationalVisits,
      heading: "Educational Visits",
      text: "STEM related days for schools across the country",
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <img className={styles.headerImage} src={headerImage} alt="" />
        <div className={styles.titleContainer}>
          <h1>Williams</h1>
          <h1 className={styles.blueText}>Experience</h1>
          <h1 className={styles.blueText}>Centre</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.textContent}>
          <h2 className={styles.blueText}>Experience Williams Racing</h2>
          <p>
            Grove, Oxfordshire has been our countryside home for over a quarter
            of a century after we outgrew the previous facilities based in
            nearby Didcot. Grove is where we develop and build the two cars
            you'll see out on track today.
          </p>
          <p>
            At our base, you'll also find our state-of-the-art Experience
            Centre, offering a modern and creative environment in which to meet,
            train, network and entertain.
          </p>
          <p>
            Finally, there's the jaw-dropping Heritage Collection one of the
            largest private collections of F1 cars in the world. Housing more
            than 40 of the team's seminal race cars, from race one right through
            to the present day.
          </p>
          <p>
            For enquiries regarding the Williams Experience Centre, please
            contact events@williamsf1.com
          </p>
        </div>
        <InPlaceVideo
          videoPlaceholder={videoPlaceholder}
          videoSource={willamsExperienceVideo}
        />
      </div>
      <div className={styles.experienceSection}>
        <img
          src={williamsExperienceBackground}
          alt=""
          className={styles.experienceSectionImage}
        />
        <div className={styles.experienceSectionContent}>
          <h3 className={styles.blueText}>Tickets & Experiences:</h3>
          <p>There's something for everyone!</p>
          <div className={styles.experienceCardContainer}>
            {experienceCards &&
              experienceCards.map((card, index) => (
                <div className={styles.card} key={index}>
                  {card?.image && (
                    <div className="cardImageContainer">
                      <img src={card.image} alt="" />
                    </div>
                  )}
                  <div className={styles.cardTextContainer}>
                    {card?.heading && <h4>{card.heading}</h4>}
                    {card?.text && <p>{card.text}</p>}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <ScrollDown />
      <TempBackButton />
    </div>
  );
};
export default WilliamsHq;
