import TopNav from "../../components/TopNav/TopNav";
import headerImage from "./assets/images/downloadHeaderImage.webp";
import styles from "./assets/css/Download.module.css";
import ClearCache from "../../components/ClearCache/ClearCache";
import ScrollDown from "../../components/ScrollDown/ScrollDown";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// CTA IMAGES
import downloadAppBackground from "./assets/images/DownloadAppBackground.webp";
import bannerBackgroundImage from "./assets/images/BannerBackground.webp";
import QRCode from "./assets/images/QRCode.webp";

// APP GALLERY IMAGES
import AppImage1 from "./assets/images/App-1.webp";
import AppImage2 from "./assets/images/App-2.webp";
import AppImage3 from "./assets/images/App-3.webp";
import AppImage4 from "./assets/images/App-4.webp";
import AppImage5 from "./assets/images/App-5.webp";
import AppImage6 from "./assets/images/App-6.webp";
import AppImage7 from "./assets/images/App-7.webp";

const GuideToF1 = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <img className={styles.headerImage} src={headerImage} alt="" />
        <div className={styles.titleContainer}>
          <h1>Download</h1>
          <h1 className={styles.blueText}>The App</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        {/* CALL TO ACTION */}
        <section className={styles.appCallToAction}>
          <div className={styles.callToActionImage}>
            <img src={downloadAppBackground} alt="" />
            <div className={styles.callToActionBanner}>
              <img src={bannerBackgroundImage} alt="" />
              {/*QR CODE */}
              <div className={styles.qrCode}>
                <img src={QRCode} alt="" />
              </div>
              {/* CTA TEXT */}
              <h2>
                Be part of the team with the official williams racing app{" "}
                <span className={styles.blueText}>
                  {" "}
                  For both iOS and Android
                </span>
              </h2>
            </div>
          </div>
          <h3 className={styles.blueText}>
            Your home for all things williams racing!
          </h3>
          <p>
            Download the official Williams Racing app to stay up-to-date with
            everything going on trackside and back at Grove during the 2023
            Formula 1 season.
          </p>
        </section>
        <section className={styles.appGallery}>
          <img src={AppImage1} alt="" />
          <img src={AppImage2} alt="" />
          <img src={AppImage3} alt="" />
          <img src={AppImage4} alt="" />
          <img src={AppImage5} alt="" />
          <img src={AppImage6} alt="" />
          <img src={AppImage7} alt="" />
        </section>
        <section className={styles.bottomSection}>
          <p>
            Join the Williams Racing team live during F1 Grand Prix weekends to
            unlock exclusive badges, put your strategy to the test on our
            free-to-play game Pit Wall Predictions, download your very own
            unique Driver Card, and more!
          </p>
          <h3 className={styles.blueText}>Support</h3>
          <p>Contact support@digital.williamsf1.com</p>
          <ClearCache />
        </section>
      </div>
      <ScrollDown />
      <TempBackButton />
    </div>
  );
};
export default GuideToF1;
