import backgroundImage from "./assets/images/AeroBackground.webp";
import styles from "./assets/css/Aero.module.css";
import TopNav from "../../components/TopNav/TopNav";
import InPlaceVideo from "../../components/InPlaceVideo/InPlaceVideo";
import aeroVideo from "./assets/videos/aeroVideo.mp4";
import videoPlaceholderImage from "./assets/images/videoPlaceholderImage.webp";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

const Aero = () => {
  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <div className={styles.pageHeader}>
        <div>
          <img
            src={backgroundImage}
            alt=""
            className={styles.backgroundImage}
          />
          <div className={styles.headingContainer}>
            <h1>Aero</h1>
          </div>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <h2>Aerodynamics</h2>
        <p>
          Last season's regulation shake-up brought about the most drastic
          change to F1 aero in recent memory. The designs of the car have
          undergone a complete overhaul to allow a driver in hot pursuit of a
          competitor to keep up with them better than ever before.
        </p>
        <p>
          The current generation of F1 cars utilise a vacuum-like effect of air
          travelling under the car's floor to pull them into the track, an
          aerodynamic phenomenon known as Ground Effect.
        </p>
        <p>Watch the video below to go inside our aerodynamics department.</p>
        <InPlaceVideo
          videoPlaceholder={videoPlaceholderImage}
          videoSource={aeroVideo}
        />
      </div>
      <TempBackButton />
    </section>
  );
};

export default Aero;
