import styles from "./assets/css/RootPage.module.css";
import TopNav from "../TopNav/TopNav";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useEffect, useState } from "react";

const RootPage = ({
  backgroundImage,
  cardImages,
  cardImageSize,
  whiteText,
  blueText,
}) => {
  const [componentMounted, setComponentMounted] = useState(false);
  useEffect(() => {
    setComponentMounted(true);
  }, []);

  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <div>
        <img
          src={backgroundImage}
          alt=""
          className={styles.backgroundImage}
          height={3840}
          width={2160}
        />
      </div>
      <div className={styles.headingContainer}>
        <h1>
          {whiteText} <span className={styles.blueText}>{blueText}</span>
        </h1>
      </div>
      <div className={styles.cardContainer}>
        {cardImages &&
          cardImages.map((card, index) => (
            <CSSTransition
              timeout={50}
              classNames="slide-relative"
              unmountOnExit
              mountOnEnter
              in={componentMounted}
              style={{
                transitionDelay: `${(0.6 * index) / cardImages.length}s`,
              }}
              key={index}
            >
              <Link to={card.linkTo}>
                <div className={styles.card}>
                  <img
                    src={card.card}
                    alt=""
                    height={cardImageSize?.height}
                    width={cardImageSize?.width}
                  />
                </div>
              </Link>
            </CSSTransition>
          ))}
      </div>
    </section>
  );
};

export default RootPage;
