import styles from "./assets/css/CurrentRace.module.css";
import currentRaceBackground from "./assets/images/currentRace.webp";
import TopNav from "../../components/TopNav/TopNav";
import currentTrackMap from "./assets/images/currentTrack.webp";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

const CurrentRace = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageHeader}>
        <TopNav />
      </div>
      <section className={styles.trackMedia}>
        <img
          src={currentRaceBackground}
          className={styles.cityImage}
          alt="Austin track"
        />
        <h1 className={styles.trackName}>ABU DHABI</h1>
        <img
          src={currentTrackMap}
          className={styles.trackMap}
          alt="Outline of current race track"
        />
      </section>
      <section className={styles.trackInfoContainer}>
        <div className={styles.weekendInfo}>
          <h2>Race Weekend</h2>
          <div className={styles.dayInfo}>
            <h3>Friday</h3>
            <p>
              <span>Practice 1: </span>13:30 - 14:30
            </p>
            <p>
              <span>Practice 2: </span>17:00 - 18:00
            </p>
          </div>
          <div className={styles.dayInfo}>
            <h3>Saturday</h3>
            <p>
              <span>Practice 3: </span>14:30 - 15:30
            </p>
            <p>
              <span>Qualifying: </span>18:00 - 19:00
            </p>
          </div>
          <div className={styles.dayInfo}>
            <h3>Sunday</h3>
            <p>
              <span>Race: </span>17:00
            </p>
          </div>
        </div>
        <div className={styles.trackInfo}>
          <div className={styles.infoText}>
            <p>First Grand Prix</p>
            <p className={styles.blueText}>2009</p>
          </div>
          <div className={styles.infoText}>
            <p>Number of Laps</p>
            <p className={styles.blueText}>58</p>
          </div>
          <div className={styles.infoText}>
            <p>Circuit Length</p>
            <p className={styles.blueText}>5.281 KM</p>
          </div>
          <div className={styles.infoText}>
            <p>Race Distance</p>
            <p className={styles.blueText}>306.183 KM</p>
          </div>
          <div className={styles.infoText}>
            <p>Lap Record</p>
            <p className={styles.blueText}>1:26.103</p>
            <p className={styles.blueText}>Max Verstappen (2021)</p>
          </div>
        </div>
      </section>
      <TempBackButton />
    </div>
  );
};

export default CurrentRace;
