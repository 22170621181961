import styles from "./assets/css/Leadership.module.css";
import backgroundImage from "./assets/images/leadershipBackgorund.webp";
import TopNav from "../../components/TopNav/TopNav";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Leaders Images
import fWilliams from "./assets/images/FWilliams.webp";
import pHead from "./assets/images/PHead.webp";
import mSavage from "./assets/images/MSavage.webp";
import jMatthews from "./assets/images/JMatthews.webp";
import jVowles from "./assets/images/JVowles.webp";
import jButton from "./assets/images/JButton.webp";
import kChandhok from "./assets/images/KChandhok.webp";

const Leadership = () => {
  const leaders = [
    {
      title: "Sir",
      fName: "Frank",
      lName: "Williams",
      role: "Founder",
      image: fWilliams,
    },
    {
      title: "Sir",
      fName: "Patrick",
      lName: "Head",
      role: "Founder",
      image: pHead,
    },
    {
      title: "",
      fName: "Matthew",
      lName: "Savage",
      role: "Board",
      image: mSavage,
    },
    {
      title: "",
      fName: "James",
      lName: "Matthews",
      role: "Board",
      image: jMatthews,
    },
    {
      title: "",
      fName: "James",
      lName: "Vowles",
      role: "Team Principal",
      image: jVowles,
    },
    {
      title: "",
      fName: "Jenson",
      lName: "Button",
      role: "Brand Ambassador",
      image: jButton,
    },
    {
      title: "",
      fName: "Karun",
      lName: "Chandhok",
      role: "Heritage Driver",
      image: kChandhok,
    },
  ];

  return (
    <section className={styles.pageContainer}>
      <div className={styles.headerContainer}>
        <TopNav />
        <div className={styles.backgroundContainer}>
          <img
            src={backgroundImage}
            className={styles.backgroundImage}
            alt=""
          />
        </div>
        <div className={styles.headingContainer}>
          <h1>Leadership</h1>
          <h1 className={styles.blueText}>Team</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.leaders}>
          {leaders &&
            leaders.map((leader, index) => (
              <div className={styles.leader} key={index}>
                <img src={leader.image} alt="" />
              </div>
            ))}
        </div>
      </div>
      <TempBackButton />
    </section>
  );
};

export default Leadership;
