import RootPage from "../../components/RootPage/RootPage";
import TempBackButton from "../../components/TempBackButton/TempBackButton";

// Car Page Background Images
import carPageBackground from "./assets/images/carMenuBackgroundImage.webp";

// Car Page Cards
import FwFactsImage from "./assets/images/FW45Facts.webp";
import TyresImage from "./assets/images/Tyres.webp";
import AreoImage from "./assets/images/Aero.webp";
import PowerUnitImage from "./assets/images/PowerUnit.webp";
import SafetyImage from "./assets/images/Safety.webp";
import SteeringWheelImage from "./assets/images/Steering_Wheel.webp";

const Car = () => {
  const carCardImages = [
    { card: FwFactsImage, linkTo: "/facts" },
    { card: TyresImage, linkTo: "/tyres" },
    { card: AreoImage, linkTo: "/aero" },
    { card: PowerUnitImage, linkTo: "/powerunit" },
    { card: SafetyImage, linkTo: "/safety" },
    { card: SteeringWheelImage, linkTo: "/steeringwheel" },
  ];

  return (
    <>
      <RootPage
        backgroundImage={carPageBackground}
        cardImages={carCardImages}
        cardImageSize={{ height: 458, width: 1864 }}
        whiteText={"FW45"}
        blueText={"2023 CAR"}
      />
      <TempBackButton />
    </>
  );
};

export default Car;
