import TopNav from "../../components/TopNav/TopNav";
import styles from "./assets/css/PowerUnit.module.css";
import TempBackButton from "../../components/TempBackButton/TempBackButton";
import backgroundImage from "./assets/images/PowerUnitBackground.webp";
import ScrollDown from "../../components/ScrollDown/ScrollDown";

const PowerUnit = () => {
  return (
    <section className={styles.pageContainer}>
      <TopNav />
      <div>
        <img src={backgroundImage} alt="" className={styles.backgroundImage} />
      </div>
      <div className={styles.headingContainer}>
        <h1 className={styles.blueText}>Mercedes-Benz</h1>
        <h1>Power Unit</h1>
      </div>
      <div className={styles.pageContent}>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <h2>Components</h2>
            <p>
              Formula 1 power units are made up of several main components.
              These are the internal combustion engine (ICE), motor generator
              unit-heat (MGU-H), motor generator unit-kinetic (MGU-K),
              turbocharger, energy store (ES), control electronics (CE) and
              exhaust.
            </p>
          </div>
          <div className={styles.card}>
            <h2>Engine</h2>
            <p>
              Our Mercedes-Benz power unit contains one of the world's most
              advanced and efficient engines with technology to recover and
              reuse energy for extra performance.
            </p>
          </div>
          <div className={styles.card}>
            <h2>MGU-H</h2>
            <p>
              This is an energy recovery system you'll find connected to the
              engine's turbocharger to convert thermal energy from exhaust gases
              into electrical energy.
            </p>
          </div>
          <div className={styles.card}>
            <h2>MGU-K</h2>
            <p>
              Found in the crankshaft of the ICE, it performs two functions: it
              helps power the engine whilst accelerating and also uses the
              energy gained under braking to recharge the batteries.
            </p>
          </div>
          <div className={styles.card}>
            <h2>Engine Store</h2>
            <p>
              This houses the energy from both the MGU-H and the MGU-K, enabling
              them to provide a power boost and control the turbocharger speed.
              It can also harvest energy from both.
            </p>
          </div>
          <div className={styles.card}>
            <h2>Control</h2>
            <p>
              These are the car's primary control systems for things such as
              torque control and energy delivery. It also records and transmits
              data to the teams and race control.
            </p>
          </div>
          <div className={styles.card}>
            <h2>Allocation</h2>
            <p>
              Each driver across the season is permitted to use only three ICEs,
              MGU-Hs, MGU-Ks and turbochargers, two ESs and CEs, and eight
              exhausts.
            </p>
          </div>
          <div className={styles.card}>
            <h2>Penalties</h2>
            <p>
              Each driver is limited as to how many power unit components we can
              use in a 23 race season. The driver will receive a grid penalty at
              the first event where the additional components are used.
            </p>
          </div>
          <div className={styles.card}>
            <h2>Engine Freeze</h2>
            <p>
              Ahead of 2022, it was agreed that the current set of components
              would be locked in until the end of 2025, halting power unit
              development before new units come in for 2026.
            </p>
          </div>
        </div>
      </div>
      <ScrollDown />
      <TempBackButton />
    </section>
  );
};

export default PowerUnit;
