import { useEffect, useState } from "react";

const ClearCache = () => {
  const [showCacheButton, setShowCacheButton] = useState(false);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [inputPassword, setInputPassword] = useState("");

  useEffect(() => {
    setShowCacheButton(navigator.onLine);
  }, [setShowCacheButton]);

  const clearCache = () => {
    if (inputPassword !== "update") return alert("Invalid Command");
    if (!navigator.onLine) {
      alert("No Connection To Perform Update");
    } else {
      const confirmAction = window.confirm(
        "Are you sure you want to clear all application data?"
      );

      if (confirmAction) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        localStorage.clear();
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
              // Unregister all service worker registrations
              for (var i = 0; i < registrations.length; i++) {
                registrations[i].unregister();
              }
            });
        }
      }
    }
    window.location.assign("/");
  };

  const inputChange = (e) => {
    setInputPassword(e.target.value);
  };

  return (
    <>
      {showCacheButton && (
        <button
          style={{
            position: "absolute",
            left: "575px",
            bottom: "260px",
            borderRadius: "50%",
            fontSize: "77px",
            height: "50px",
            width: "50px",
            background: "none",
            border: "none",
          }}
          onClick={() => setShowAdminPanel(true)}
        />
      )}
      {showAdminPanel && (
        <div
          style={{
            position: "fixed",
            inset: "0 0 0 0",
            backgroundColor: "#041e42ee",
            zIndex: "99999",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="password"
            style={{
              fontSize: "70px",
              padding: "10px 20px",
            }}
            value={inputPassword}
            onChange={inputChange}
          />
          <button
            type="button"
            style={{
              fontSize: "70px",
              padding: "10px 20px",
              borderRadius: "10px",
              border: "3px solid #fff",
              marginTop: "40px",
              backgroundColor: "transparent",
              color: "#fff",
              fontFamily: "NeutrafaceCDJLight",
              textTransform: "uppercase",
            }}
            onClick={clearCache}
          >
            Submit
          </button>
          <button
            type="button"
            style={{
              fontSize: "70px",
              padding: "10px 20px",
              borderRadius: "10px",
              border: "3px solid #fff",
              marginTop: "40px",
              backgroundColor: "transparent",
              color: "#fff",
              fontFamily: "NeutrafaceCDJLight",
              textTransform: "uppercase",
            }}
            onClick={() => setShowAdminPanel(false)}
          >
            Back
          </button>
        </div>
      )}
    </>
  );
};
export default ClearCache;
